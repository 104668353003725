<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">

            <el-form-item :label='$t("adminParms.name2")' prop="publicName">
                <el-input v-model="fromValue.publicName" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('adminParms.name3')" prop="publicKey">
                <el-input v-model="fromValue.publicKey" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('adminParms.name4')" prop="publicValue">
                <el-input v-model="fromValue.publicValue" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('adminParms.name5')" prop="validateCode">
                <el-input v-model="fromValue.validateCode" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('adminParms.name6')" prop="systemFlag">
                <el-select v-model="fromValue.systemFlag" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option :label="item.label" :value="item.value" v-for='item in dicArray' :key = 'item.id'  />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('adminParms.name7')" prop="status">
                <el-select v-model="fromValue.status" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option :label="item.label" :value="item.value" v-for='item in statusArray' :key = 'item.id'  />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('adminParms.name8')" prop="publicType">
                <el-select v-model="fromValue.publicType" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option :label="item.label" :value="item.value" v-for='item in typeArray' :key = 'item.id'  />
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from '../dictionary/addRules'
    const { $t } = getCurrentInstance().proxy;
    import { getDataStyle } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        publicId: '',publicName: '',publicKey:'',
        publicValue: '',validateCode: '',systemFlag: '',
        status: '',publicType: '',
    })

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const dicArray = ref([]); getDataStyle('dict_type',(e)=>{dicArray.value = e})
    const statusArray = ref([]); getDataStyle('enable_status',(e)=>{statusArray.value = e})
    const typeArray = ref([]); getDataStyle('param_type',(e)=>{typeArray.value = e})

    const emit = defineEmits(['successFun','closeFun'])

    
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.publicId
                    Api_.addParmsListAll(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.putParmsObjAll(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
        }else{
            for(let index in fromValue){
                if(index !== 'tenantId'){
                    fromValue[index] = ''
                }
            }
        }
    }
    defineExpose({init_,completeFun})
</script>
<style lang="scss" scoped>
</style>